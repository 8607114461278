import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateModel from './components/CreateModel';
import AddClass from './components/AddClass';
import Predict from './components/Predict';
import ListModels from './components/ListModels';
import Login from './components/Login';
import SignUp from './components/SignUp';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Tutorial from './components/Tutorial';
import PrivateRoute from './components/PrivateRoute';
import Inferences from './components/Inferences';
import ClassifyAndSaveImages from './components/ClassifyAndSaveImages';
import SearchAndRetrieveImages from './components/SearchAndRetrieveImages';
import SearchAndRetrieveWebImages from './components/SearchAndRetrieveWebImages';
import GenerateImages from './components/GenerateImages';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ForgotUsername from './components/ForgotUsername';
import PublicFeatures from './components/PublicFeatures';
import MarketingContentGenerator from "./components/MarketingContentGenerator";
import DesignGenerator from './components/DesignGenerator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { AuthProvider } from './context/AuthContext';

// Import the new components
import TurboTagInfo from './components/TurboTagInfo';
import SnapSortInfo from './components/SnapSortInfo';

// Theme setup
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(82,11,202)',
    },
    secondary: {
      main: '#ff4081',
    },
  },
});

function App() {
  // State for managing the mode (TurboTag or SnapSort)
  const [mode, setMode] = useState(null); // Start with no mode selected

  // Model ID and Model Name state
  const [modelId, setModelId] = useState("");
  const [modelName, setModelName] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        {/* Wrap the whole app inside AuthProvider */}
        <Router>
          <div className="App">
            {/* Pass mode and setMode to NavBar */}
            <NavBar mode={mode} setMode={setMode} />
            <Routes>
              {/* Home Route */}
              <Route path="/" element={<Home setMode={setMode} />} />

              {/* Authentication Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-username" element={<ForgotUsername />} />

              {/* Product Info Routes */}
              <Route path="/turbo-tag-info" element={<TurboTagInfo />} />
              <Route path="/snap-sort-info" element={<SnapSortInfo />} />

              <Route path="/public-features" element={<PublicFeatures />} />

              {/* TurboTag Routes */}
              <Route
                path="/create-model"
                element={
                  <PrivateRoute
                    component={CreateModel}
                    setModelId={setModelId}
                    setModelName={setModelName}
                  />
                }
              />
              <Route
                path="/add-class"
                element={
                  <PrivateRoute
                    component={AddClass}
                    modelId={modelId}
                    modelName={modelName}
                  />
                }
              />
              <Route
                path="/predict"
                element={
                  <PrivateRoute
                    component={Predict}
                    modelId={modelId}
                    modelName={modelName}
                  />
                }
              />
              <Route
                path="/list-models"
                element={
                  <PrivateRoute
                    component={ListModels}
                    setModelId={setModelId}
                    setModelName={setModelName}
                  />
                }
              />

              {/* Inference Route */}
              <Route
                path="/models/:modelId/inferences"
                element={<PrivateRoute component={Inferences} />}
              />

              {/* SnapSort Routes */}
              <Route
                path="/classify-save"
                element={<PrivateRoute component={ClassifyAndSaveImages} />}
              />
              <Route
                path="/search-images"
                element={<PrivateRoute component={SearchAndRetrieveImages} />}
              />
              <Route
                path="/web-search-images"
                element={<PrivateRoute component={SearchAndRetrieveWebImages} />}
              />
              <Route path="/generate-images" element={<GenerateImages />} />
              {/* <Route path="/generate-marketing-content" element={<MarketingContentGenerator />} /> */}
              <Route path="/generate-design" element={<DesignGenerator />} />

              {/* Tutorial Route */}
              <Route path="/tutorial" element={<Tutorial />} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

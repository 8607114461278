import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { TextField, Button, Container, Typography, Box, Alert, Paper, Card, CardContent, IconButton } from "@mui/material";
import ModelIcon from '@mui/icons-material/Category';  // Import a suitable Material UI icon
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon

const CreateModel = ({ setModelId, setModelName }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isModelCreated, setIsModelCreated] = useState(false);
  const [error, setError] = useState("");
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true); // New state for card visibility
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);

      const response = await api.post("/models", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { id, name: modelName } = response.data;
      setModelId(id);
      setModelName(modelName);
      setIsModelCreated(true);
      setError("");

      // Redirect to Add Class after creating the model
      navigate(`/add-class`);
    } catch (error) {
      setError("Error creating model");
    }
  };

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        display: "flex", 
        flexDirection: "column", // Ensure vertical stacking
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh", // Full viewport height
        padding: "1rem",
        paddingTop: '100px'
      }}
    >
      {/* Form Section */}
      <Paper elevation={3} sx={{ padding: "2rem", width: "100%", borderRadius: "12px", mb: 8 }}> {/* Increased mb to 6 for more space */}
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center", color: "#550FCC", mb: 2 }}>
          Create a New Model
        </Typography>

        {error && (
          <Box mt={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Model Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            InputProps={{
              sx: { borderRadius: "8px" },
            }}
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            required
            InputProps={{
              sx: { borderRadius: "8px" },
            }}
          />

          <Box mt={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                padding: "10px 20px",
                borderRadius: "8px",
                backgroundColor: "#550FCC",
                '&:hover': {
                  backgroundColor: "#8338ec",
                },
              }}
            >
              Create Model
            </Button>
          </Box>
        </form>

        {isModelCreated && (
          <Box mt={2}>
            <Alert severity="success">Model Created: {name}</Alert>
          </Box>
        )}
      </Paper>

      {/* Explanatory Section Below */}
      {showExplanatoryCard && (
        <Card
          sx={{
            width: "90%", // Make the card take 90% of the container width
            maxWidth: "800px", // Set a larger max width
            margin: "2rem auto", // Center the card horizontally and add vertical spacing
            padding: "2rem", // Add consistent padding
            borderRadius: "12px", // Rounded corners for better aesthetics
            backgroundColor: "#f9f9f9",
            position: "relative",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            <ModelIcon sx={{ fontSize: "4rem", color: "#550FCC" }} />{" "}
            {/* Enlarged icon for better visibility */}
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
            >
              What does "Creating a Model" mean?
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ lineHeight: 1.6 }} // Improve readability with line height
            >
              Creating a model is the first step in building your AI solution. A
              model is like a blank canvas where you can add classes (categories or
              labels) that your AI will learn to recognize. Once you have created a
              model, you can start adding classes and training it to identify
              different objects or categories. This step sets the foundation for
              your AI-powered project!
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default CreateModel;

// FolderSelector.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  Tooltip, // Import Tooltip
} from '@mui/material';
import { Folder, FolderOpen, ChevronRight, ChevronDown, Share } from 'lucide-react'; // Correctly import Share
import AddIcon from '@mui/icons-material/Add';
import api from '../api';
import FolderActions from './FolderActions'; // Add import for FolderActions

const FolderSelector = ({ selectedFolder, onFolderSelect, showRoot = true, sharedFolders = [] }) => {
  const [folders, setFolders] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(null);
  const [error, setError] = useState('');
  const [folderError, setFolderError] = useState('');
  const [allFolders, setAllFolders] = useState([]);

  // Fetch folders on component mount
  useEffect(() => {
    fetchFolders();
  }, []);

  // Updated fetchFolders with enhanced error handling
  const fetchFolders = async () => {
    try {
      const response = await api.get('/folders', {
        params: {
          recursive: true,
          include_deleted: false
        }
      });
      
      const foldersData = response.data.folders || [];
      setAllFolders(foldersData);
      setFolders(foldersData);  // Also update the folders state
  
      if (selectedFolder) {
        const updatedFolder = foldersData.find(f => f.id === selectedFolder.id);
        if (updatedFolder) {
          onFolderSelect(updatedFolder);  // Use onFolderSelect instead of setSelectedFolder
        }
      }
  
      return foldersData;
    } catch (error) {
      setFolderError('Failed to fetch folders');
      return [];
    }
  };

  // Utility function to get folder path
  const getFolderPath = (folderId, folderList) => {
    const path = [];
    let current = folderList.find(f => f.id === folderId);
    
    while (current) {
      path.unshift({
        id: current.id,
        name: current.name
      });
      current = folderList.find(f => f.id === current.parent_folder_id);
    }
    
    return path;
  };

  // Function to render the folder path as breadcrumbs
  const renderFolderPath = () => {
    if (!selectedFolder) return null;
    
    const pathSegments = getFolderPath(selectedFolder.id, folders);
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '1rem' }}>
        <Typography variant="body2" color="textSecondary">
          Path: 
        </Typography>
        {pathSegments.map((segment, index) => (
          <React.Fragment key={index}>
            {index > 0 && <ChevronRight className="w-4 h-4" />}
            <Typography variant="body2">{segment.name}</Typography>
          </React.Fragment>
        ))}
      </Box>
    );
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
  
    try {
      setError('');
  
      const response = await api.post('/folders', {
        name: newFolderName,
        parent_folder_id: currentParentId,
        description: "",
        tags: []
      });
  
      setNewFolderName('');
      setCreateDialogOpen(false);
  
      const updatedFolders = await fetchFolders();
  
      if (currentParentId) {
        setExpandedFolders(prev => ({
          ...prev,
          [currentParentId]: true
        }));
      }
  
    } catch (error) {
      setError(error.response?.data?.detail || 'Failed to create folder');
    }
  };

  const renderFolderTree = (parentId = null, level = 0) => {
    const folderItems = folders.filter(f => 
      f.parent_folder_id === parentId &&
      !f.is_deleted // Only show non-deleted folders
    );

    return (
      <List>
        {folderItems.map((folder) => (
          <Box key={folder.id}>
            <ListItem
              button
              sx={{ pl: level * 4 }}
              selected={selectedFolder?.id === folder.id}
              onClick={() => onFolderSelect(folder)}
              onDragOver={(e) => handleDragOver(e, folder)}
              onDrop={(e) => handleDrop(e, folder)}
              draggable
              onDragStart={(e) => handleDragStart(e, folder, 'folder')}
            >
              <ListItemIcon 
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolder(folder.id);
                }}
                className="cursor-pointer"
              >
                {expandedFolders[folder.id] ? 
                  <ChevronDown className="w-4 h-4 rotate-90" /> : 
                  <ChevronRight className="w-4 h-4" />
                }
              </ListItemIcon>
              <ListItemIcon>
                {selectedFolder?.id === folder.id ? 
                  <FolderOpen className="w-4 h-4" /> : 
                  <Folder className="w-4 h-4" />
                }
              </ListItemIcon>
              <ListItemText primary={folder.name} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentParentId(folder.id);
                    setCreateDialogOpen(true);
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
                {/* Add FolderActions here */}
                <FolderActions 
                  folder={folder}
                  onFolderUpdate={fetchFolders}
                  onFolderDelete={() => {
                    fetchFolders();
                    if (selectedFolder?.id === folder.id) {
                      onFolderSelect(null); // Use onFolderSelect instead of setSelectedFolder
                    }
                    // Removed searchImages() call as it's not needed here
                  }}
                />
              </Box>
              {sharedFolders.includes(folder.id) && (
                <Tooltip title="Shared folder">
                  <Share className="w-4 h-4 text-blue-500" />
                </Tooltip>
              )}
            </ListItem>
            {expandedFolders[folder.id] && renderFolderTree(folder.id, level + 1)}
          </Box>
        ))}
      </List>
    );
  };

  // Drag-and-Drop Handlers (if needed)
  const handleDragStart = (e, item, type) => {
    // Implement drag start logic if drag-and-drop is required
  };

  const handleDragOver = (e, folder) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, targetFolder) => {
    e.preventDefault();
    e.stopPropagation();

    // Implement drop logic if drag-and-drop is required
  };

  const toggleFolder = (folderId) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  return (
    <Box className="w-full">
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="subtitle1">Select Folder</Typography>
        <Button
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => {
            setCurrentParentId(null);
            setCreateDialogOpen(true);
          }}
          variant="outlined"
          size="small"
        >
          NEW FOLDER
        </Button>
      </Box>
      
      {/* Display error message if any */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          onClose={() => setError('')}
        >
          {error}
        </Alert>
      )}

      {/* Display the current folder path as breadcrumbs */}
      {renderFolderPath()}

      {loading ? (
        <Typography>Loading folders...</Typography>
      ) : (
        renderFolderTree()
      )}

      <Dialog 
        open={createDialogOpen} 
        onClose={() => {
          setCreateDialogOpen(false);
          setNewFolderName('');
          setError(''); // Clear error when closing dialog
        }}
      >
        <DialogTitle>
          Create New Folder
          {currentParentId && " in " + (folders.find(f => f.id === currentParentId)?.name || 'selected folder')}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleCreateFolder();
              }
            }}
          />
          <Box className="flex justify-end mt-4 gap-2">
            <Button onClick={() => {
              setCreateDialogOpen(false);
              setNewFolderName('');
              setError('');
            }}>Cancel</Button>
            <Button 
              variant="contained" 
              onClick={handleCreateFolder}
              disabled={!newFolderName.trim()}
            >
              Create
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FolderSelector;

import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { 
  ChevronRight,
  ChevronDown,
  Folder,
  FolderOpen
} from 'lucide-react';
import FolderActions from './FolderActions';

const FolderTree = ({ 
  folders, 
  selectedFolder,
  onFolderSelect,
  onDropInFolder,
  sharedFolders = [],
  handleFolderDelete
}) => {
  const [expandedFolders, setExpandedFolders] = useState({});

  useEffect(() => {
    if (selectedFolder?.id) {
      setExpandedFolders(prev => ({
        ...prev,
        [selectedFolder.id]: true
      }));
    }
  }, [selectedFolder]);

  const toggleFolder = (folderId) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const handleDragStart = (e, item, type) => {
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, targetFolder) => {
    e.preventDefault();
    e.stopPropagation();
    onDropInFolder && onDropInFolder(null, null, targetFolder);
  };

  const renderFolderTree = (parentId = null, level = 0) => {
    const folderItems = folders.filter(f => 
      f.parent_folder_id === (parentId || null) &&
      !f.is_deleted
    );

    return (
      <List disablePadding>
        {folderItems.map((folder) => (
          <Box key={folder.id}>
            <ListItem
              button
              sx={{ pl: level * 4 }}
              selected={selectedFolder?.id === folder.id}
              onClick={() => onFolderSelect(folder)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, folder)}
              draggable
              onDragStart={(e) => handleDragStart(e, folder, 'folder')}
            >
              <ListItemIcon
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolder(folder.id);
                }}
                sx={{ cursor: 'pointer', minWidth: 'auto', mr: 1 }}
              >
                {expandedFolders[folder.id] ?
                  <ChevronDown className="w-4 h-4" /> :
                  <ChevronRight className="w-4 h-4" />
                }
              </ListItemIcon>
              <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                {selectedFolder?.id === folder.id ?
                  <FolderOpen className="w-4 h-4" /> :
                  <Folder className="w-4 h-4" />
                }
              </ListItemIcon>
              <ListItemText primary={folder.name} />
              <FolderActions
                folder={folder}
                onFolderUpdate={async () => {
                  // Refresh folder list
                  // If currently selected folder is this folder, reselect it
                  // Note: Parent will handle the refresh and re-selection
                  // We'll simply re-invoke the parent's onFolderSelect if needed

                  await onFolderSelect(folder);
                }}
                onFolderDelete={() => handleFolderDelete(folder.id)}
              />
            </ListItem>
            {expandedFolders[folder.id] && renderFolderTree(folder.id, level + 1)}
          </Box>
        ))}
      </List>
    );
  };

  return (
    <Box className="border rounded-md p-2">
      {renderFolderTree()}
    </Box>
  );
};

export default FolderTree;

import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Paper,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  Card,
  CardMedia,
  CardActionArea,
  Grid,
  styled,
  useMediaQuery,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import {
  Send as SendIcon,
  AttachFile as AttachFileIcon,
  Search as SearchIcon,
  Image as ImageIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  OpenInNew as OpenInNewIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  PictureAsPdf as PictureAsPdfIcon, // Icon for PDFs
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import api from "../api";
import AuthContext from "../context/AuthContext";
import ReactMarkdown from "react-markdown";

// Styled component for Drag-and-Drop area
const DragAndDropBox = styled(Box)(({ theme, isdragover }) => ({
  border: "2px dashed #ccc",
  borderRadius: "8px",
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: isdragover ? "#f0f0f0" : "#fafafa",
  transition: "background-color 0.3s ease",
}));

// Helper function to parse model responses
const parseModelResponse = (response) => {
  const searchSuggestionsSet = new Set();
  const generationPromptsSet = new Set();
  let cleanText = response;

  // Function to clean asterisks and quotes from text
  const cleanFormat = (text) => {
    return text
      .replace(/\*\*/g, "") // Remove double asterisks
      .replace(/["']/g, "") // Remove quotes
      .trim();
  };

  // Function to extract labeled suggestions
  const extractLabeledItems = (text, label) => {
    const items = [];
    const regex = new RegExp(`${label}:\\s*(.+)(?=\\n|$)`, "gi");
    let match;

    while ((match = regex.exec(text)) !== null) {
      const item = cleanFormat(match[1]);
      if (item) items.push(item);
    }

    return items;
  };

  // First, find the "Reference Images:" section
  const referenceSection = response.match(/Reference Images?:\s*([\s\S]*?)(?=\n\n|Generate Elements?:|$)/i);
  if (referenceSection) {
    // Extract search terms from this section
    const searchItems = referenceSection[1]
      .split("\n")
      .filter((line) => line.trim())
      .map((line) => {
        // Clean up bullets, numbers, and other formatting
        return line.replace(/^[-•*\d.\s]+/, "").replace(/\*\*/g, "").trim();
      })
      .filter((item) => item);

    searchItems.forEach((item) => searchSuggestionsSet.add(item));

    // Remove the processed section from cleanText
    cleanText = cleanText.replace(referenceSection[0], "");
  }

  // Find and process explicit "Search Suggestion:" entries
  extractLabeledItems(cleanText, "Search Suggestion").forEach((item) =>
    searchSuggestionsSet.add(item)
  );

  // Find and process "Generate Elements:" section
  const generateSection = response.match(/Generate Elements?:\s*([\s\S]*?)(?=\n\n|$)/i);
  if (generateSection) {
    const generateItems = generateSection[1]
      .split("\n")
      .filter((line) => line.trim())
      .map((line) => {
        return line.replace(/^[-•*\d.\s]+/, "").replace(/\*\*/g, "").trim();
      })
      .filter((item) => item);

    generateItems.forEach((item) => generationPromptsSet.add(item));

    // Remove the processed section from cleanText
    cleanText = cleanText.replace(generateSection[0], "");
  }

  // Find and process explicit "Generation Prompt:" entries
  extractLabeledItems(cleanText, "Generation Prompt").forEach((item) =>
    generationPromptsSet.add(item)
  );

  // Clean up the main text
  cleanText = cleanText
    .split("\n")
    .filter((line) => {
      const trimmedLine = line.trim();
      return (
        trimmedLine &&
        !trimmedLine.toLowerCase().startsWith("reference image") &&
        !trimmedLine.toLowerCase().startsWith("search suggestion") &&
        !trimmedLine.toLowerCase().startsWith("generate element") &&
        !trimmedLine.toLowerCase().startsWith("generation prompt")
      );
    })
    .join("\n")
    .trim();

  return {
    text: cleanText,
    searchSuggestions: Array.from(searchSuggestionsSet)
      .filter(Boolean)
      .map((item) => item.replace(/^["']|["']$/g, "")), // Remove any remaining quotes
    generationPrompts: Array.from(generationPromptsSet)
      .filter(Boolean)
      .map((item) => item.replace(/^["']|["']$/g, "")), // Remove any remaining quotes
  };
};

// Message content component with formatting
const MessageContent = ({ content, onSearch, onGenerate }) => {
  const { text, searchSuggestions, generationPrompts } = parseModelResponse(
    content
  );

  return (
    <Box>
      <ReactMarkdown
        children={text}
        components={{
          p: ({ node, ...props }) => (
            <Typography variant="body1" {...props} />
          ),
          strong: ({ node, ...props }) => <strong {...props} />,
          em: ({ node, ...props }) => <em {...props} />,
        }}
      />
      {(searchSuggestions.length > 0 || generationPrompts.length > 0) && (
        <Box sx={{ mt: 2 }}>
          {searchSuggestions.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{ mb: 1 }}
              >
                Reference Images:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {searchSuggestions.map((query, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    size="small"
                    onClick={() => onSearch(query)}
                    startIcon={<SearchIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    {query}
                  </Button>
                ))}
              </Box>
            </Box>
          )}
          {generationPrompts.length > 0 && (
            <Box>
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{ mb: 1 }}
              >
                Generate Elements:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {generationPrompts.map((prompt, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    size="small"
                    onClick={() => onGenerate(prompt)}
                    startIcon={<ImageIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    {prompt}
                  </Button>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

// Add this new component for the thinking animation
const ThinkingIndicator = () => (
  <Box
    sx={{
      p: 2,
      maxWidth: "50%",
      alignSelf: "flex-start",
    }}
  >
    <Paper
      elevation={1}
      sx={{
        p: 2,
        backgroundColor: "#F5F5F5",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <CircularProgress size={16} />
      <Typography variant="body1" color="text.secondary">
        Design Copilot is thinking...
      </Typography>
    </Paper>
  </Box>
);

// Function to render attachments based on type
const renderAttachment = (att) => {
  if (att.type.startsWith("image")) {
    return (
      <img
        src={att.url}
        alt="Attached"
        style={{
          maxWidth: "200px",
          maxHeight: "200px",
          borderRadius: "4px",
        }}
      />
    );
  } else if (att.type === "pdf") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: 1,
          border: "1px solid #ccc",
          borderRadius: "4px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <PictureAsPdfIcon color="error" />
        <Typography variant="body2" color="textPrimary">
          PDF Document
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          href={att.url}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<OpenInNewIcon />}
        >
          View
        </Button>
      </Box>
    );
  }
  return null;
};

const DesignGenerator = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Existing states
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedPreviews, setUploadedPreviews] = useState([]);
  // Removed recentChats state
  // Removed showChatsModal state

  // New states for panels and suggestions
  const [showAttachmentPanel, setShowAttachmentPanel] = useState(true);
  const [showSuggestionsPanel, setShowSuggestionsPanel] = useState(true);
  const [activeSuggestions, setActiveSuggestions] = useState({
    searchSuggestions: [],
    generationPrompts: [],
    searchResults: [],
  });

  // State for drag-and-drop
  const [isDragOver, setIsDragOver] = useState(false);

  // States for mobile drawers
  const [attachmentDrawerOpen, setAttachmentDrawerOpen] = useState(false);
  const [suggestionsDrawerOpen, setSuggestionsDrawerOpen] = useState(false);

  // New state for thinking indicator
  const [isThinking, setIsThinking] = useState(false);

  // Scroll to bottom when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages, isThinking]);

  // Fetch chat history and suggestions from localStorage on initial load
  useEffect(() => {
    const savedSessionId = localStorage.getItem("designChatSessionId");
    const savedSuggestions = localStorage.getItem("designCopilotSuggestions");

    if (savedSessionId) {
      setSessionId(savedSessionId);
      fetchChatHistory(savedSessionId);
    }

    if (savedSuggestions) {
      const parsedSuggestions = JSON.parse(savedSuggestions);
      setActiveSuggestions(parsedSuggestions);
      refreshSuggestionsImages(parsedSuggestions);
    }
  }, []);

  // Removed useEffect for fetching recent chats

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const fetchChatHistory = async (sessionId) => {
    try {
      const response = await api.get(`/design-copilot/chat/${sessionId}/history`);
      const { messages } = response.data;

      // Map backend messages to frontend format
      const formattedMessages = messages.map((msg) => ({
        role: msg.role,
        content: msg.content,
        attachments: msg.attachments
          ? msg.attachments.map((att) => {
              if (att.type === "image" || att.type === "pdf") {
                // Handle image and PDF attachments
                if (att.url) {
                  return {
                    type: att.type,
                    url: att.url,
                  };
                }
                // If file is base64 encoded, create an object URL
                else if (att.content) {
                  const mimeType =
                    att.type === "pdf" ? "application/pdf" : "image/jpeg";
                  const fileBlob = base64ToBlob(att.content, mimeType);
                  const fileUrl = URL.createObjectURL(fileBlob);
                  return {
                    type: att.type,
                    url: fileUrl,
                  };
                }
              }
              return att;
            })
          : [],
      }));

      setMessages(formattedMessages);
      localStorage.setItem("designChatSessionId", sessionId); // Save to localStorage
    } catch (error) {
      console.error("Error fetching chat history:", error);
      setError("Failed to load the conversation.");
      // Remove invalid session ID
      localStorage.removeItem("designChatSessionId");
      setSessionId(null);
      setMessages([]);
    }
  };

  // Function to convert base64 string to Blob
  const base64ToBlob = (base64, mime) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: mime });
  };

  // Helper function to resize an image
const resizeImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const MAX_DIMENSION = 2048;
        let width = img.width;
        let height = img.height;
        
        // Check if resizing is needed
        if (width <= MAX_DIMENSION && height <= MAX_DIMENSION) {
          resolve(file);
          return;
        }
        
        // Calculate new dimensions while maintaining aspect ratio
        if (width > height) {
          if (width > MAX_DIMENSION) {
            height = Math.round((height * MAX_DIMENSION) / width);
            width = MAX_DIMENSION;
          }
        } else {
          if (height > MAX_DIMENSION) {
            width = Math.round((width * MAX_DIMENSION) / height);
            height = MAX_DIMENSION;
          }
        }
        
        // Create canvas and resize image
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        
        // Convert canvas to blob
        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          resolve(resizedFile);
        }, 'image/jpeg', 0.9); // Using JPEG format with 90% quality
      };
    };
  });
};

  // Removed fetchRecentChats and related functions

  // Modified handleFileUpload function in the DesignGenerator component
const handleFileUpload = async (files) => {
  try {
    const processedFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        // Only process image files, leave PDFs as is
        if (file.type.startsWith('image/')) {
          return await resizeImage(file);
        }
        return file;
      })
    );
    
    const validFiles = processedFiles.filter(
      (file) => file.type.startsWith('image/') || file.type === 'application/pdf'
    );
    
    if (validFiles.length === 0) return;
    
    const newFiles = [...uploadedFiles, ...validFiles];
    setUploadedFiles(newFiles);
    
    const newPreviews = validFiles.map((file) => URL.createObjectURL(file));
    setUploadedPreviews((prev) => [...prev, ...newPreviews]);
  } catch (error) {
    console.error('Error processing files:', error);
    setError('Failed to process uploaded files');
  }
};

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const { files } = event.dataTransfer;
    handleFileUpload(files);
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    const imageItems = Array.from(items).filter(
      (item) => item.type.indexOf("image") !== -1
    );
    if (imageItems.length > 0) {
      const files = imageItems.map((item) => item.getAsFile());
      handleFileUpload(files);
    }
  };

  useEffect(() => {
    // Add paste event listener to the window
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    return () => {
      uploadedPreviews.forEach((preview) => URL.revokeObjectURL(preview));

      // Don't clear suggestions on unmount anymore since we want to persist them
    };
  }, [uploadedPreviews]);

  // Function to update suggestions and persist to localStorage
  const updateSuggestions = (newSuggestions) => {
    setActiveSuggestions(newSuggestions);
    localStorage.setItem(
      "designCopilotSuggestions",
      JSON.stringify(newSuggestions)
    );
  };

  // Function to refresh web search results based on suggestions
  const refreshSuggestionsImages = async (suggestions) => {
    if (!suggestions.searchSuggestions?.length) return;

    try {
      const searchResults = await Promise.all(
        suggestions.searchSuggestions.map(async (query) => ({
          query,
          results: await fetchWebSearchResults(query),
        }))
      );

      // Filter out empty results
      const validSearchResults = searchResults.filter(
        (result) => result.results.length > 0
      );

      updateSuggestions({
        ...suggestions,
        searchResults: validSearchResults,
      });
    } catch (error) {
      console.error("Error refreshing suggestions images:", error);
    }
  };

  // Function to fetch web search results
  const fetchWebSearchResults = async (query) => {
    try {
      const formData = new FormData();
      formData.append("query_text", query);

      const response = await api.post("/web-images/search", formData, {
        params: {
          page_size: 4, // Limit results for suggestions panel
          page_number: 1,
        },
      });

      return response.data.results || [];
    } catch (error) {
      console.error("Error fetching web search results:", error);
      return [];
    }
  };

  // Function to handle image load errors
  const handleImageError = (event) => {
    event.target.src = "/placeholder-image.jpg"; // Replace with your placeholder image path
  };

  // Modified message handling to include web search results
  const sendMessage = async () => {
    if (!inputMessage.trim() && uploadedFiles.length === 0) return;

    try {
      setLoading(true);
      setIsThinking(true); // Set thinking state true
      setError("");

      const formData = new FormData();
      formData.append("message", inputMessage);

      if (sessionId) {
        formData.append("session_id", sessionId);
      }

      uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });

      // Add the user's message immediately
      const newUserMessage = {
        role: "user",
        content: inputMessage,
        attachments: uploadedPreviews.map((preview, index) => ({
          type: uploadedFiles[index].type.startsWith("image/")
            ? "image"
            : "pdf",
          url: preview,
        })),
      };

      setMessages((prev) => [...prev, newUserMessage]);
      setInputMessage("");
      setUploadedFiles([]);
      setUploadedPreviews([]);

      const response = await api.post("/design-copilot/chat", formData);

      const newAssistantMessage = {
        role: "assistant",
        content: response.data.response,
      };

      const { searchSuggestions, generationPrompts } = parseModelResponse(
        response.data.response
      );

      // Fetch web search results for all search suggestions
      const searchResults = await Promise.all(
        searchSuggestions.map(async (query) => ({
          query,
          results: await fetchWebSearchResults(query),
        }))
      );

      const validSearchResults = searchResults.filter(
        (result) => result.results.length > 0
      );

      const newSuggestions = {
        searchSuggestions,
        generationPrompts,
        searchResults: validSearchResults,
      };

      updateSuggestions(newSuggestions);
      setMessages((prev) => [...prev, newAssistantMessage]);

      if (response.data.session_id) {
        setSessionId(response.data.session_id);
        localStorage.setItem("designChatSessionId", response.data.session_id);
      }

      setShowSuggestionsPanel(true);
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message");
    } finally {
      setLoading(false);
      setIsThinking(false); // Set thinking state false
    }
  };

  const startNewChat = () => {
    setMessages([]);
    setSessionId(null);
    localStorage.removeItem("designChatSessionId");
    clearSuggestions();
    setError("");
  };

  // Function to clear suggestions and remove from localStorage
  const clearSuggestions = () => {
    setActiveSuggestions({
      searchSuggestions: [],
      generationPrompts: [],
      searchResults: [],
    });
    localStorage.removeItem("designCopilotSuggestions");
  };

  const executeSearch = (query) => {
    navigate("/web-search-images", {
      state: {
        searchQuery: query,
        from: "design-copilot",
      },
    });
  };

  const executeGeneration = (prompt) => {
    navigate("/generate-images", {
      state: {
        generationPrompt: prompt,
        from: "design-copilot",
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: isMobile ? "8rem" : "6rem", mb: "4rem" }}>
      {/* AppBar for Mobile with Menu Icons */}
      {isMobile && (
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open attachment"
              onClick={() => setAttachmentDrawerOpen(true)}
            >
              <AttachFileIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
              Design Copilot
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="open suggestions"
              onClick={() => setSuggestionsDrawerOpen(true)}
            >
              <ImageIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}

      {/* Header with Title (Button removed) */}
      {!isMobile && (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", color: "#550FCC" }}>
            Design Copilot
          </Typography>
        </Box>
      )}

      {/* AppBar on Mobile pushes content below */}
      {isMobile && <Toolbar />}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          height: isMobile ? "auto" : "calc(100vh - 240px)",
        }}
      >
        {/* Attachment Panel */}
        {!isMobile && (
          <Box
            sx={{
              width: showAttachmentPanel ? 300 : 40,
              transition: "width 0.3s ease",
              position: "relative",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                height: "100%",
                p: 2,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <IconButton
                onClick={() => setShowAttachmentPanel(!showAttachmentPanel)}
                sx={{ position: "absolute", right: 0, top: 8 }}
              >
                {showAttachmentPanel ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>

              {showAttachmentPanel && (
                <Box
                  sx={{
                    mt: 4,
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Attachments
                  </Typography>
                  <DragAndDropBox
                    isdragover={isDragOver ? 1 : 0}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Drag and drop images or PDFs here, paste from clipboard, or click to
                      upload.
                    </Typography>
                  </DragAndDropBox>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      mt: 2,
                      flex: 1,
                      overflowY: "auto", // Enable scrolling
                    }}
                  >
                    {uploadedPreviews.map((preview, index) => {
                      const fileType = uploadedFiles[index].type.startsWith("image/")
                        ? "image"
                        : "pdf";
                      return (
                        <Box key={index} sx={{ position: "relative" }}>
                          {fileType === "image" ? (
                            <img
                              src={preview}
                              alt={`Preview ${index}`}
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "4px",
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                p: 1,
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                backgroundColor: "#f9f9f9",
                              }}
                            >
                              <PictureAsPdfIcon color="error" />
                              <Typography variant="body2" color="textPrimary">
                                PDF Document
                              </Typography>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                href={preview}
                                target="_blank"
                                rel="noopener noreferrer"
                                startIcon={<OpenInNewIcon />}
                              >
                                View
                              </Button>
                            </Box>
                          )}
                          <IconButton
                            size="small"
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              backgroundColor: "rgba(255, 255, 255, 0.8)",
                            }}
                            onClick={() => {
                              setUploadedFiles((files) =>
                                files.filter((_, i) => i !== index)
                              );
                              setUploadedPreviews((previews) =>
                                previews.filter((_, i) => i !== index)
                              );
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Paper>
          </Box>
        )}

        {/* Chat Area */}
        <Paper
          elevation={3}
          sx={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          {/* Messages Area */}
          <Box
            ref={chatContainerRef}
            sx={{
              flex: 1,
              overflow: "auto",
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {messages.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  alignSelf: msg.role === "user" ? "flex-end" : "flex-start",
                  maxWidth: "80%",
                }}
              >
                <Paper
                  elevation={1}
                  sx={{
                    p: 2,
                    backgroundColor:
                      msg.role === "user" ? "#E3F2FD" : "#F5F5F5",
                  }}
                >
                  {msg.attachments && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        mb: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {msg.attachments.map((att, idx) => (
                        <React.Fragment key={idx}>
                          {renderAttachment(att)}
                        </React.Fragment>
                      ))}
                    </Box>
                  )}
                  {msg.role === "user" ? (
                    <Typography variant="body1">{msg.content}</Typography>
                  ) : (
                    <MessageContent
                      content={msg.content}
                      onSearch={executeSearch}
                      onGenerate={executeGeneration}
                    />
                  )}
                </Paper>
              </Box>
            ))}
            {isThinking && <ThinkingIndicator />}
          </Box>

          {/* Input Area */}
          <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
            {uploadedPreviews.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  mb: 2,
                  flexWrap: "wrap",
                }}
              >
                {uploadedPreviews.map((preview, index) => {
                  const fileType = uploadedFiles[index].type.startsWith("image/")
                    ? "image"
                    : "pdf";
                  return (
                    <Box key={index} sx={{ position: "relative" }}>
                      {fileType === "image" ? (
                        <img
                          src={preview}
                          alt={`Preview ${index}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "4px",
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            p: 1,
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <PictureAsPdfIcon color="error" />
                          <Typography variant="body2" color="textPrimary">
                            PDF Document
                          </Typography>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            href={preview}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<OpenInNewIcon />}
                          >
                            View
                          </Button>
                        </Box>
                      )}
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          setUploadedFiles((files) =>
                            files.filter((_, i) => i !== index)
                          );
                          setUploadedPreviews((previews) =>
                            previews.filter((_, i) => i !== index)
                          );
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            )}

            <Box sx={{ display: "flex", gap: 1 }}>
              {!isMobile && (
                <IconButton
                  onClick={() => fileInputRef.current?.click()}
                  color="primary"
                >
                  <AttachFileIcon />
                </IconButton>
              )}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Ask about your design..."
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
                multiline
                maxRows={4}
              />
              <IconButton
                onClick={sendMessage}
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
              <IconButton
                onClick={startNewChat}
                color="primary"
                title="Start New Chat"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
            {/* Hidden File Input */}
            <input
              type="file"
              ref={fileInputRef}
              hidden
              multiple
              accept="image/*,application/pdf" // Accept images and PDFs
              onChange={(e) => handleFileUpload(e.target.files)}
            />
          </Box>
        </Paper>

        {/* Suggestions Panel */}
        {!isMobile && showSuggestionsPanel && (
          <Box
            sx={{
              width: 350,
              transition: "width 0.3s ease",
              position: "relative",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                height: "100%",
                p: 2,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <IconButton
                onClick={() => setShowSuggestionsPanel(false)}
                sx={{ position: "absolute", left: 0, top: 8 }}
              >
                <ChevronLeftIcon />
              </IconButton>

              <Box
                sx={{
                  mt: 4,
                  flex: 1,
                  overflowY: "auto", // Enable scrolling
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Suggestions
                </Typography>

                {activeSuggestions.searchResults?.map((searchResult, index) => (
                  <Box key={index} sx={{ mb: 4 }}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                    >
                      {searchResult.query}
                    </Typography>
                    <Grid container spacing={1}>
                      {searchResult.results.map((result, imgIndex) => {
                        const imageLink = result.contentUrl || result.thumbnailUrl;
                        if (!imageLink) return null;

                        return (
                          <Grid item xs={6} key={imgIndex}>
                            <Card sx={{ height: "100%" }}>
                              <CardActionArea
                                onClick={() => window.open(imageLink, "_blank")}
                                sx={{ height: "100%" }}
                              >
                                <CardMedia
                                  component="img"
                                  height="120"
                                  image={
                                    result.thumbnailUrl || "/placeholder-image.jpg"
                                  }
                                  alt={result.name || "Search result"}
                                  onError={handleImageError}
                                  sx={{ objectFit: "cover" }}
                                />
                              </CardActionArea>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                ))}

                {activeSuggestions.generationPrompts?.length > 0 && (
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                    >
                      Generate Elements
                    </Typography>
                    <List>
                      {activeSuggestions.generationPrompts.map(
                        (prompt, index) => (
                          <ListItem key={index} disablePadding sx={{ mb: 1 }}>
                            <Button
                              fullWidth
                              variant="outlined"
                              onClick={() => executeGeneration(prompt)}
                              startIcon={<ImageIcon />}
                              endIcon={<OpenInNewIcon />}
                              sx={{
                                justifyContent: "flex-start",
                                textTransform: "none",
                                height: "auto",
                                whiteSpace: "normal",
                                textAlign: "left",
                                py: 1,
                              }}
                            >
                              {prompt}
                            </Button>
                          </ListItem>
                        )
                      )}
                    </List>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
        )}
      </Box>

      {/* Removed Recent Chats Dialog */}

      {/* Attachment Drawer for Mobile */}
      <Drawer
        anchor="left"
        open={attachmentDrawerOpen}
        onClose={() => setAttachmentDrawerOpen(false)}
      >
        <Box
          sx={{
            width: 300,
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Attachments
          </Typography>
          <DragAndDropBox
            isdragover={isDragOver ? 1 : 0}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
          >
            <Typography variant="body2" color="textSecondary">
              Drag and drop images or PDFs here, paste from clipboard, or click to
              upload.
            </Typography>
          </DragAndDropBox>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
              flex: 1,
              overflowY: "auto", // Enable scrolling
            }}
          >
            {uploadedPreviews.map((preview, index) => {
              const fileType = uploadedFiles[index].type.startsWith("image/")
                ? "image"
                : "pdf";
              return (
                <Box key={index} sx={{ position: "relative" }}>
                  {fileType === "image" ? (
                    <img
                      src={preview}
                      alt={`Preview ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        p: 1,
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <PictureAsPdfIcon color="error" />
                      <Typography variant="body2" color="textPrimary">
                        PDF Document
                      </Typography>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        href={preview}
                        target="_blank"
                        rel="noopener noreferrer"
                        startIcon={<OpenInNewIcon />}
                      >
                        View
                      </Button>
                    </Box>
                  )}
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    onClick={() => {
                      setUploadedFiles((files) =>
                        files.filter((_, i) => i !== index)
                      );
                      setUploadedPreviews((previews) =>
                        previews.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Drawer>

      {/* Suggestions Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={suggestionsDrawerOpen}
        onClose={() => setSuggestionsDrawerOpen(false)}
      >
        <Box
          sx={{
            width: 350,
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Suggestions
          </Typography>

          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {activeSuggestions.searchResults?.map((searchResult, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  gutterBottom
                >
                  {searchResult.query}
                </Typography>
                <Grid container spacing={1}>
                  {searchResult.results.map((result, imgIndex) => {
                    const imageLink = result.contentUrl || result.thumbnailUrl;
                    if (!imageLink) return null;

                    return (
                      <Grid item xs={6} key={imgIndex}>
                        <Card sx={{ height: "100%" }}>
                          <CardActionArea
                            onClick={() => window.open(imageLink, "_blank")}
                            sx={{ height: "100%" }}
                          >
                            <CardMedia
                              component="img"
                              height="120"
                              image={
                                result.thumbnailUrl || "/placeholder-image.jpg"
                              }
                              alt={result.name || "Search result"}
                              onError={handleImageError}
                              sx={{ objectFit: "cover" }}
                            />
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            ))}

            {activeSuggestions.generationPrompts?.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  gutterBottom
                >
                  Generate Elements
                </Typography>
                <List>
                  {activeSuggestions.generationPrompts.map(
                    (prompt, index) => (
                      <ListItem key={index} disablePadding sx={{ mb: 1 }}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => executeGeneration(prompt)}
                          startIcon={<ImageIcon />}
                          endIcon={<OpenInNewIcon />}
                          sx={{
                            justifyContent: "flex-start",
                            textTransform: "none",
                            height: "auto",
                            whiteSpace: "normal",
                            textAlign: "left",
                            py: 1,
                          }}
                        >
                          {prompt}
                        </Button>
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
    </Container>
  );
};

export default DesignGenerator;
// FolderActions.js

import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import api from '../api';

const FolderActions = ({ folder, onFolderUpdate, onFolderDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newName, setNewName] = useState(folder?.name || '');
  const [targetUsername, setTargetUsername] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleMenuClick = (event) => {
    event.stopPropagation(); // Prevent folder selection when clicking menu
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setError('');
    setSuccess('');
  };

  // Handle Renaming the Folder
  const handleRename = async () => {
    if (!newName.trim()) {
      setError('Folder name cannot be empty.');
      return;
    }

    try {
      await api.put(`/folders/${folder.id}`, { name: newName });
      onFolderUpdate();
      setRenameDialogOpen(false);
      setSuccess('Folder renamed successfully.');
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 'Error renaming folder.';
      setError(errorMessage);
    }
  };

  // Handle Sharing the Folder
  const handleShare = async () => {
    if (!targetUsername.trim()) {
      setError('Username cannot be empty.');
      return;
    }

    try {
      await api.post(`/folders/${folder.id}/share`, { target_username: targetUsername });
      setShareDialogOpen(false);
      setTargetUsername('');
      setSuccess('Folder shared successfully.');
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 'Error sharing folder.';
      setError(errorMessage);
    }
  };

  // Handle Deleting the Folder
  const handleDelete = async () => {
    try {
      await api.delete(`/folders/${folder.id}`);
      onFolderDelete(folder.id); // Pass the deleted folder's ID to the parent
      setDeleteDialogOpen(false);
      setSuccess('Folder deleted successfully.');
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 'Error deleting folder.';
      setError(errorMessage);
    }
  };

  return (
    <>
      {/* Action Menu Button */}
      <IconButton onClick={handleMenuClick} aria-label="folder actions">
        <MoreVertIcon />
      </IconButton>

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Rename Folder */}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleMenuClose();
            setRenameDialogOpen(true);
            setNewName(folder.name); // Reset name to current folder name
          }}
        >
          <EditIcon fontSize="small" style={{ marginRight: 8 }} />
          Rename
        </MenuItem>

        {/* Share Folder */}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleMenuClose();
            setShareDialogOpen(true);
            setTargetUsername(''); // Reset target username
          }}
        >
          <ShareIcon fontSize="small" style={{ marginRight: 8 }} />
          Share
        </MenuItem>

        {/* Delete Folder */}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleMenuClose();
            setDeleteDialogOpen(true);
          }}
        >
          <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
          Delete
        </MenuItem>
      </Menu>

      {/* Rename Folder Dialog */}
      <Dialog
        open={renameDialogOpen}
        onClose={() => {
          setRenameDialogOpen(false);
          setError('');
          setSuccess('');
        }}
      >
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          {/* Display Error Message */}
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setError('')}
            >
              {error}
            </Alert>
          )}
          {/* Display Success Message */}
          {success && (
            <Alert
              severity="success"
              sx={{ mb: 2 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="New Folder Name"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleRename();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRenameDialogOpen(false);
              setError('');
              setSuccess('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRename}
            variant="contained"
            disabled={!newName.trim()}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Share Folder Dialog */}
      <Dialog
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setError('');
          setSuccess('');
        }}
      >
        <DialogTitle>Share Folder</DialogTitle>
        <DialogContent>
          {/* Display Error Message */}
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setError('')}
            >
              {error}
            </Alert>
          )}
          {/* Display Success Message */}
          {success && (
            <Alert
              severity="success"
              sx={{ mb: 2 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Username to Share With"
            fullWidth
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleShare();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShareDialogOpen(false);
              setError('');
              setSuccess('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleShare}
            variant="contained"
            disabled={!targetUsername.trim()}
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Folder Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setError('');
          setSuccess('');
        }}
      >
        <DialogTitle>Delete Folder</DialogTitle>
        <DialogContent>
          {/* Display Error Message */}
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setError('')}
            >
              {error}
            </Alert>
          )}
          {/* Display Success Message */}
          {success && (
            <Alert
              severity="success"
              sx={{ mb: 2 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
          <Typography>
            Are you sure you want to delete the folder "{folder.name}" and all its contents?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setError('');
              setSuccess('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FolderActions;

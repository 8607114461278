// NavBar.jsx
import React, { useContext, useEffect, useState } from 'react';
import {
  AppBar, Toolbar, Button, Menu, MenuItem, IconButton, Drawer, List, ListItem, ListItemText
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../logo.png';  // Default light logo
import logoDark from '../logo_dark.png';  // Dark logo for scrolling and inside pages
import AuthContext from '../context/AuthContext';  // Import AuthContext

const NavBar = ({ mode, setMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);  // Get user and logout from context

  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);  // State for mobile drawer
  const [anchorEl, setAnchorEl] = useState(null);  // For products dropdown
  const [activeProduct, setActiveProduct] = useState(null);  // Track active product (TurboTag or SnapSort)

  // Restore mode from localStorage on mount
  useEffect(() => {
    const excludedPaths = ['/', '/login', '/signup'];
    if (excludedPaths.includes(location.pathname)) {
      setActiveProduct(null);
    } else {
      const storedMode = localStorage.getItem('selectedMode');
      if (storedMode) {
        setMode(storedMode);
        setActiveProduct(storedMode);
      }
    }
  }, [location.pathname, setMode]);

  // Handle scrolling for the home page only
  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        setIsScrolled(scrollPosition > window.innerHeight - 80);
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      setIsScrolled(true);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    logout();
    localStorage.removeItem('selectedMode');
    setActiveProduct(null);
    navigate('/');
  };

  const handleHomeClick = () => {
    setActiveProduct(null);
    setMode(null);
    localStorage.removeItem('selectedMode');
    navigate('/');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // TurboTag and SnapSort click handlers
  const handleTurboTagClick = () => {
    setMode('TurboTag');
    setActiveProduct('TurboTag');
    localStorage.setItem('selectedMode', 'TurboTag');
    navigate('/turbo-tag-info');
    handleProductsClose(); // Close menu after selection
  };

  const handleSnapSortClick = () => {
    setMode('SnapSort');
    setActiveProduct('SnapSort');
    localStorage.setItem('selectedMode', 'SnapSort');
    navigate('/snap-sort-info');
    handleProductsClose(); // Close menu after selection
  };

  // Products dropdown
  const handleProductsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProductsClose = () => {
    setAnchorEl(null);
  };

  // Scroll to features section on the home page
  const scrollToFeatures = () => {
    const featuresSection = document.getElementById('features-section');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isHomePage = location.pathname === '/';
  const isProductPage = location.pathname.startsWith('/turbo-tag') || location.pathname.startsWith('/snap-sort');
  const isNavbarDark = isScrolled || !isHomePage;

  // Mobile drawer content
  const drawer = (
    <List>
      <ListItem button component={Link} to="/" onClick={() => { handleHomeClick(); handleDrawerToggle(); }}>
        <ListItemText primary="Home" />
      </ListItem>
      {!activeProduct && (
        <>
          {!isProductPage && (
            <ListItem button onClick={() => { scrollToFeatures(); handleDrawerToggle(); }}>
              <ListItemText primary="Features" />
            </ListItem>
          )}
          <ListItem button onClick={handleProductsClick}>
            <ListItemText primary="Products" />
          </ListItem>
          <Menu
            id="products-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProductsClose}
            // Ensure the menu appears above the drawer
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
          >
            <MenuItem onClick={handleSnapSortClick}>SnapSort: AI Image Management</MenuItem>
            <MenuItem onClick={handleTurboTagClick}>TurboTag: Custom Image Tagging</MenuItem>
          </Menu>
        </>
      )}
      {activeProduct && !user && (
        <ListItem button component={Link} to={`/${activeProduct.toLowerCase()}-info`} onClick={handleDrawerToggle}>
          <ListItemText primary={`${activeProduct} Info`} />
        </ListItem>
      )}
      {user && (
        <>
          {activeProduct === 'TurboTag' && (
            <>
              <ListItem button component={Link} to="/turbo-tag-info" onClick={handleDrawerToggle}>
                <ListItemText primary="TurboTag Info" />
              </ListItem>
              <ListItem button component={Link} to="/create-model" onClick={handleDrawerToggle}>
                <ListItemText primary="Create Model" />
              </ListItem>
              <ListItem button component={Link} to="/list-models" onClick={handleDrawerToggle}>
                <ListItemText primary="List Models" />
              </ListItem>
              <ListItem button component={Link} to="/add-class" onClick={handleDrawerToggle}>
                <ListItemText primary="Add Class" />
              </ListItem>
              <ListItem button component={Link} to="/predict" onClick={handleDrawerToggle}>
                <ListItemText primary="Predict" />
              </ListItem>
            </>
          )}
          {activeProduct === 'SnapSort' && (
            <>
              <ListItem button component={Link} to="/snap-sort-info" onClick={handleDrawerToggle}>
                <ListItemText primary="SnapSort Info" />
              </ListItem>
              <ListItem button component={Link} to="/classify-save" onClick={handleDrawerToggle}>
                <ListItemText primary="Classify & Save Images" />
              </ListItem>
              <ListItem button component={Link} to="/search-images" onClick={handleDrawerToggle}>
                <ListItemText primary="Search Images" />
              </ListItem>
              <ListItem button component={Link} to="/web-search-images" onClick={handleDrawerToggle}>
                <ListItemText primary="Search Web Images" />
              </ListItem>
              <ListItem button component={Link} to="/generate-images" onClick={handleDrawerToggle}>
                <ListItemText primary="Generate Images" />
              </ListItem>
              {/* <ListItem button component={Link} to="/generate-marketing-content" onClick={handleDrawerToggle}>
                <ListItemText primary="Generate Marketing Content" />
              </ListItem> */}
              {/* New ListItem for Generate Design */}
              <ListItem button component={Link} to="/generate-design" onClick={handleDrawerToggle}>
                <ListItemText primary="Generate Design" />
              </ListItem>
            </>
          )}
          <ListItem button onClick={() => { handleLogout(); handleDrawerToggle(); }}>
            <ListItemText primary="Logout" />
          </ListItem>
        </>
      )}
      {!user && (
        <>
          <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
            <ListItemText primary="Login" />
          </ListItem>
          <ListItem button component={Link} to="/signup" onClick={handleDrawerToggle}>
            <ListItemText primary="Sign Up" />
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: isNavbarDark ? '#fff' : 'transparent',
          boxShadow: isNavbarDark ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
          transition: 'background-color 0.3s ease',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Hamburger Icon */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: 'block', md: 'none' },
              color: isNavbarDark ? '#000' : '#fff', // Change color based on isNavbarDark
              marginRight: 'auto',  // Move to the left; change to marginLeft: 'auto' if you want it on the right
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <img
            src={isNavbarDark ? logoDark : logo}
            alt="Logo"
            style={{ height: 40, cursor: 'pointer' }}
            onClick={handleHomeClick}
          />

          {/* Desktop Menu */}
          <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Button
              color="inherit"
              onClick={handleHomeClick}
              sx={{
                display: { xs: 'none', md: 'inline-flex' },
                fontSize: '0.875rem', // Reduced font size
                fontWeight: 'bold',
                color: isNavbarDark ? '#000' : '#fff',
              }}
            >
              Home
            </Button>

            {!activeProduct && !isProductPage && (
              <Button
                color="inherit"
                onClick={scrollToFeatures}
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  fontSize: '0.875rem', // Reduced font size
                  fontWeight: 'bold',
                  color: isNavbarDark ? '#000' : '#fff',
                }}
              >
                Features
              </Button>
            )}

            {!activeProduct && (
              <>
                <Button
                  color="inherit"
                  onClick={handleProductsClick}
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem', // Reduced font size
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Products
                </Button>
                <Menu
                  id="products-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleProductsClose}
                >
                  <MenuItem onClick={handleSnapSortClick}>SnapSort: AI Image Management</MenuItem>
                  <MenuItem onClick={handleTurboTagClick}>TurboTag: Custom Image Tagging</MenuItem>
                </Menu>
              </>
            )}

            {activeProduct === 'TurboTag' && user && (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/turbo-tag-info"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem', // Reduced font size
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  TurboTag Info
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/create-model"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Create Model
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/list-models"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  List Models
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/add-class"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Add Class
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/predict"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Predict
                </Button>
              </>
            )}

            {activeProduct === 'SnapSort' && user && (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/snap-sort-info"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  SnapSort Info
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/classify-save"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Classify & Save Images
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/search-images"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Search Images
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/web-search-images"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Search Web Images
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/generate-images"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Generate Images
                </Button>
                {/* <Button
                  color="inherit"
                  component={Link}
                  to="/generate-marketing-content"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Generate Marketing Content
                </Button> */}
                {/* New Button for Generate Design */}
                <Button
                  color="inherit"
                  component={Link}
                  to="/generate-design"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Design Copilot
                </Button>
              </>
            )}

            {user && (
              <Button
                color="inherit"
                onClick={handleLogout}
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  color: isNavbarDark ? '#000' : '#fff',
                }}
              >
                Logout
              </Button>
            )}

            {!user && (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/login"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Login
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/signup"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    color: isNavbarDark ? '#000' : '#fff',
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default NavBar;

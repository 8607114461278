// ClassifyAndSaveImages.js

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CloseIcon from "@mui/icons-material/Close";
import api from "../api";

import FolderSelector from './FolderSelector';
import FolderBreadcrumb from './FolderBreadcrumb';
import FolderActions from './FolderActions';

const ClassifyAndSaveImages = () => {
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [externalImages, setExternalImages] = useState([]);
  const [classSuggestions, setClassSuggestions] = useState([]);
  const [userClasses, setUserClasses] = useState([]);
  const [isClassified, setIsClassified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imageTags, setImageTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [annotations, setAnnotations] = useState("");
  const [commonClass, setCommonClass] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const [resizingProgress, setResizingProgress] = useState(0);

  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderError, setFolderError] = useState('');
  const [allFolders, setAllFolders] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [folderLoading, setFolderLoading] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      // First fetch folders
      await fetchFolders();

      // Then fetch models and handle external images
      const fetchModels = async () => {
        try {
          const response = await api.get("/models");
          setModels(response.data);
        } catch (error) {
          console.error("Failed to fetch models", error);
          setError("Failed to fetch models");
        }
      };

      await fetchModels();

      if (location.state && location.state.images) {
        setExternalImages(location.state.images);
        setFiles([]);
      }

      if (location.state && location.state.selectedModelId) {
        setSelectedModelId(location.state.selectedModelId);
      }
    };

    initialize();
  }, [location.state]);

  useEffect(() => {
    if (selectedFolder) {
      console.log('Selected folder changed:', selectedFolder);
      // Ensure parent folders are expanded
      const expandParentFolders = () => {
        setExpandedFolders(prev => {
          const newState = { ...prev };
          let current = allFolders.find(f => f.id === selectedFolder.id);
          while (current?.parent_folder_id) {
            newState[current.parent_folder_id] = true;
            current = allFolders.find(f => f.id === current.parent_folder_id);
          }
          return newState;
        });
      };
      expandParentFolders();
    }
  }, [selectedFolder?.id, allFolders]);

  const fetchFolders = async () => {
    try {
      setFolderLoading(true);
      const response = await api.get('/folders', {
        params: {
          recursive: true
        }
      });
      const foldersData = response.data.folders || [];
      console.log('Fetched folders:', foldersData);

      // If we have a selected folder, verify it still exists and refresh its data
      if (selectedFolder) {
        const updatedSelectedFolder = foldersData.find(f => f.id === selectedFolder.id);
        if (updatedSelectedFolder) {
          setSelectedFolder(updatedSelectedFolder);
        }
      }

      setAllFolders(foldersData);
      return foldersData;
    } catch (error) {
      console.error('Error fetching folders:', error);
      setFolderError('Failed to fetch folders');
      return [];
    } finally {
      setFolderLoading(false);
    }
  };

  const getFolderBreadcrumbPath = (folder, allFoldersList) => {
    const path = [];
    let current = folder;
    while (current) {
      path.unshift({
        id: current.id,
        name: current.name
      });
      current = allFoldersList.find(f => f.id === current.parent_folder_id);
    }
    return path;
  };

  const resizeImage = (file, maxWidth = 224, maxHeight = 224) => {
    return new Promise((resolve, reject) => {
      const fileName = file.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const resizedFile = new File([blob], fileName, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            },
            "image/jpeg",
            0.75
          );
        };
        img.onerror = (err) => {
          console.error("Image load error:", err);
          reject(err);
        };
        img.src = event.target.result;
      };
      reader.onerror = (err) => {
        console.error("FileReader error:", err);
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const resetStates = () => {
    setFiles([]);
    setExternalImages([]);
    setClassSuggestions([]);
    setUserClasses([]);
    setIsClassified(false);
    setImageTags([]);
    setNewTags([]);
    setAnnotations("");
    setCommonClass("");
    setSelectedModelId("");
    setSelectedFolder(null);
    setFolderError("");
    setAllFolders([]);
    setExpandedFolders({});
  };

  const classifyImages = async () => {
    setLoading(true);
    setError("");
    setResizingProgress(0);

    try {
      let response;
      if (externalImages.length > 0) {
        const data = {
          image_urls: externalImages,
          ...(selectedModelId && { model_id: selectedModelId }),
        };

        response = await api.post("/images/classify", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        const formData = new FormData();
        const resizedFiles = [];

        for (let i = 0; i < files.length; i++) {
          try {
            const resizedFile = await resizeImage(files[i]);
            resizedFiles.push(resizedFile);
            setResizingProgress(Math.round(((i + 1) / files.length) * 100));
          } catch (error) {
            setError(`Error resizing image: ${files[i].name}`);
            console.error(`Error resizing image ${files[i].name}:`, error);
            continue;
          }
        }

        for (const resizedFile of resizedFiles) {
          formData.append("files", resizedFile);
        }
        if (selectedModelId) {
          formData.append("model_id", selectedModelId);
        }

        response = await api.post("/images/classify", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      setClassSuggestions(response.data.classification_results);
      setIsClassified(true);
      setUserClasses(
        new Array(
          externalImages.length > 0 ? externalImages.length : files.length
        ).fill("")
      );

      const resultsLength =
        externalImages.length > 0 ? externalImages.length : files.length;

      setImageTags(
        response.data.classification_results.map((result) => [
          ...(result.suggested_tags || []),
        ])
      );
      setNewTags(new Array(resultsLength).fill(""));
    } catch (error) {
      setError("Error classifying images");
      console.error("Classification Error:", error);
      if (error.response && error.response.data) {
        setError(
          "Error classifying images: " + JSON.stringify(error.response.data.detail)
        );
      }
    } finally {
      setLoading(false);
      setResizingProgress(0);
    }
  };

  const saveImages = async () => {
    setLoading(true);
    setError("");
    setFolderError("");

    try {
      if (externalImages.length > 0) {
        const data = {
          image_urls: externalImages,
          common_class: commonClass,
          annotations: annotations,
          class_names: userClasses.map(
            (className, i) =>
              className || commonClass || classSuggestions[i].suggested_class
          ),
          suggested_tags: imageTags,
          folder_id: selectedFolder?.id,
        };

        await api.post("/images/save-from-urls", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        const formData = new FormData();

        formData.append("common_class", commonClass);
        formData.append("annotations", annotations || "");
        if (selectedFolder) {
          formData.append("folder_id", selectedFolder.id);
        }

        for (let i = 0; i < files.length; i++) {
          const className =
            userClasses[i] || commonClass || classSuggestions[i].suggested_class;
          formData.append("files", files[i]);
          formData.append("class_names", className);
        }

        formData.append("suggested_tags_json", JSON.stringify(imageTags));

        await api.post("/images/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      alert("Images saved successfully");
      resetStates();
    } catch (error) {
      if (error.response?.data?.detail?.includes('folder')) {
        setFolderError(error.response.data.detail);
      } else {
        setError("Error saving images");
      }
      console.error("Save Images Error:", error);
      if (error.response && error.response.data) {
        if (!error.response.data.detail?.includes('folder')) {
          setError(
            "Error saving images: " + JSON.stringify(error.response.data.detail)
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddImageTag = (index) => {
    const tag = newTags[index].trim();
    if (tag && !imageTags[index].includes(tag)) {
      const updatedImageTags = [...imageTags];
      updatedImageTags[index].push(tag);
      setImageTags(updatedImageTags);

      const updatedNewTags = [...newTags];
      updatedNewTags[index] = "";
      setNewTags(updatedNewTags);
    }
  };

  const handleDeleteImageTag = (index, tagToDelete) => {
    const updatedImageTags = [...imageTags];
    updatedImageTags[index] = updatedImageTags[index].filter(
      (tag) => tag !== tagToDelete
    );
    setImageTags(updatedImageTags);
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
    setClassSuggestions([]);
    setUserClasses([]);
    setIsClassified(false);
    setExternalImages([]);
    setImageTags([]);
    setNewTags([]);
    setAnnotations("");
    setCommonClass("");
    setSelectedFolder(null);
    setFolderError('');
    setAllFolders([]);
    setExpandedFolders({});
  };

  const handleFolderSelect = async (folder) => {
    try {
      setFolderError('');
      console.log('Selecting folder:', folder);

      // First update the selected folder
      setSelectedFolder(folder);

      // Then get the latest folders
      await fetchFolders();

      // Ensure the parent folder is expanded
      if (folder?.parent_folder_id) {
        setExpandedFolders(prev => ({
          ...prev,
          [folder.parent_folder_id]: true
        }));
      }
    } catch (error) {
      console.error('Error selecting folder:', error);
      setFolderError('Failed to select folder');
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{ marginTop: "8rem", textAlign: "center", marginBottom: "4rem" }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "2rem", color: "#550FCC" }}
      >
        Classify and Save Images
      </Typography>

      {error && (
        <Alert severity="error" sx={{ marginBottom: "2rem" }}>
          {error}
        </Alert>
      )}

      {externalImages.length === 0 && (
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ marginBottom: "1rem" }}
        />
      )}

      <Box sx={{ marginBottom: "1rem", maxWidth: "500px", margin: "0 auto" }}>
        <FormControl fullWidth>
          <InputLabel id="model-select-label">Select a Model (optional)</InputLabel>
          <Select
            labelId="model-select-label"
            value={selectedModelId}
            label="Select a Model (optional)"
            onChange={(e) => setSelectedModelId(e.target.value)}
          >
            <MenuItem value="">
              <em>Use Default Classification</em>
            </MenuItem>
            {models.map((model) => (
              <MenuItem key={model.id} value={model.id}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={classifyImages}
        disabled={loading || (files.length === 0 && externalImages.length === 0)}
        sx={{
          marginTop: "1rem",
          marginBottom: "2rem",
          padding: "10px 20px",
          borderRadius: "8px",
        }}
      >
        {loading ? <CircularProgress size={24} /> : "Classify Images"}
      </Button>

      {resizingProgress > 0 && (
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          Resizing images: {resizingProgress}%
        </Typography>
      )}

      {isClassified && (
        <>
          <TextField
            fullWidth
            label="Enter a common class for all images (optional)"
            variant="outlined"
            value={commonClass}
            onChange={(e) => setCommonClass(e.target.value)}
            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          />

          {classSuggestions.map((suggestion, index) => (
            <Card key={index} sx={{ marginBottom: "1rem", padding: "1rem" }}>
              <CardContent>
                <Typography variant="h6">
                  <strong>Image:</strong>{" "}
                  {externalImages.length > 0
                    ? externalImages[index]
                    : files[index].name}
                </Typography>
                <img
                  src={
                    externalImages.length > 0
                      ? externalImages[index]
                      : URL.createObjectURL(files[index])
                  }
                  alt={`Image ${index}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    marginTop: "1rem",
                  }}
                />
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  <strong>Suggested Class:</strong>{" "}
                  {suggestion.suggested_class || "Unknown"}
                </Typography>

                <TextField
                  fullWidth
                  label="Enter your own class for this image (optional)"
                  variant="outlined"
                  value={userClasses[index]}
                  onChange={(e) => {
                    const newClasses = [...userClasses];
                    newClasses[index] = e.target.value;
                    setUserClasses(newClasses);
                  }}
                  sx={{ marginTop: "1rem" }}
                  placeholder={commonClass || suggestion.suggested_class}
                />

                {suggestion.suggested_tags &&
                  suggestion.suggested_tags.length > 0 && (
                    <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      <Typography
                        variant="body1"
                        sx={{ marginBottom: "0.5rem" }}
                      >
                        <strong>Suggested Tags:</strong>
                      </Typography>
                      {suggestion.suggested_tags.map((tag, tagIndex) => (
                        <Chip
                          key={tagIndex}
                          label={tag}
                          onDelete={() => handleDeleteImageTag(index, tag)}
                          sx={{ marginRight: "8px", marginBottom: "8px" }}
                          color="primary"
                        />
                      ))}
                    </Box>
                  )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <TextField
                    label="Enter a tag"
                    value={newTags[index]}
                    onChange={(e) => {
                      const updatedNewTags = [...newTags];
                      updatedNewTags[index] = e.target.value;
                      setNewTags(updatedNewTags);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddImageTag(index);
                      }
                    }}
                    sx={{
                      marginTop: "0.5rem",
                      marginRight: "1rem",
                      flexGrow: 1,
                    }}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleAddImageTag(index)}
                    sx={{
                      marginTop: "0.5rem",
                      minWidth: "100px",
                      padding: "10px 10px",
                      borderRadius: "8px",
                    }}
                  >
                    Add Tag
                  </Button>
                </Box>

                <Box sx={{ marginBottom: "1rem" }}>
                  {imageTags[index].map((tag, tagIdx) => (
                    <Chip
                      key={tagIdx}
                      label={tag}
                      onDelete={() => handleDeleteImageTag(index, tag)}
                      sx={{ marginRight: "8px", marginBottom: "8px" }}
                      color="secondary"
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          ))}

          <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <Typography variant="subtitle1" gutterBottom>
              Select Destination Folder (Optional)
            </Typography>
            <FolderSelector
              selectedFolder={selectedFolder}
              onFolderSelect={handleFolderSelect}
              showRoot={true}
              onFolderUpdate={fetchFolders}
            />
            {folderError && (
              <Alert severity="error" sx={{ marginTop: "1rem" }}>
                {folderError}
              </Alert>
            )}
          </Box>

          {selectedFolder && (
            <Box sx={{ marginTop: "1rem", marginBottom: "2rem" }}>
              <Typography variant="subtitle2" gutterBottom>
                Selected Folder:
              </Typography>
              <FolderBreadcrumb
                path={[
                  { id: 'root', name: 'Root' },
                  ...(selectedFolder ? getFolderBreadcrumbPath(selectedFolder, allFolders) : [])
                ]}
                onNavigate={(folder) => setSelectedFolder(folder)}
              />
              <FolderActions 
                folder={selectedFolder}
                onFolderUpdate={fetchFolders}
                onFolderDelete={() => {
                  fetchFolders();
                  setSelectedFolder(null);
                }}
              />
            </Box>
          )}

          <TextField
            label="Add annotations"
            value={annotations}
            onChange={(e) => setAnnotations(e.target.value)}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            sx={{ marginBottom: "2rem" }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={saveImages}
            disabled={loading}
            sx={{
              marginTop: "2rem",
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Save Images"}
          </Button>
        </>
      )}

      {showExplanatoryCard && (
        <Card
          sx={{
            width: "90%",
            maxWidth: "800px",
            margin: "2rem auto",
            padding: "2rem",
            borderRadius: "12px",
            backgroundColor: "#f9f9f9",
            position: "relative",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <ImageSearchIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />{" "}
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", mt: 2, mb: 1 }}
          >
            Why classify and save images?
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
            Whether you're a designer looking to better manage your image
            library or a business professional organizing visual data,
            classifying and saving images helps you quickly search for
            specific content. Organize your images in folders, use tags 
            and annotations to enhance searchability, and make it easier 
            to find exactly what you need, improving your workflow and 
            productivity.
          </Typography>
        </Card>
      )}
    </Container>
  );
};

export default ClassifyAndSaveImages;

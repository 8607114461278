import React, { useState, useRef } from "react";
import api from "../api";
import { Button, Container, Typography, Box, Card, CardContent, CardActions, Avatar, CircularProgress, Paper, IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';  // Icon for explanation
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon for the close button

const Predict = ({ modelId, modelName }) => {
  const [file, setFile] = useState(null);
  const [topPrediction, setTopPrediction] = useState(null);
  const [confidence, setConfidence] = useState(null);
  const [isPredictionDone, setIsPredictionDone] = useState(false);
  const [explanation, setExplanation] = useState(null);  // Store GPT-4 explanation
  const [loading, setLoading] = useState(false);  // Add loading state
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true); // New state for card visibility

  const predict = async () => {
    if (!modelId || !file) {
      alert("Please select a model and upload an image file.");
      return;
    }

    setLoading(true); // Set loading to true while waiting for response
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post(`/models/${modelId}/predict`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const [predictedClass, score] = response.data.predictions[0];
      setTopPrediction(predictedClass);
      setConfidence((score * 100).toFixed(2));
      setIsPredictionDone(true);
      setExplanation(null); // Reset explanation for a new prediction
    } catch (error) {
      alert("Error during prediction: " + error.message);
    } finally {
      setLoading(false); // Set loading back to false after response
    }
  };

  // Fetch explanation using GPT-4
  const getExplanation = async () => {
    if (!topPrediction || !confidence) {
      alert("Please run the prediction first.");
      return;
    }

    setLoading(true); // Set loading to true while waiting for response
    const formData = new FormData();
    formData.append("predicted_class", topPrediction);
    formData.append("confidence", confidence / 100);  // Convert back to decimal
    formData.append("file", file);  // Include the file for CLIP-based understanding

    try {
      const response = await api.post(`/models/${modelId}/explanation`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setExplanation(response.data.explanation);
    } catch (error) {
      alert("Error generating explanation: " + error.message);
    } finally {
      setLoading(false); // Set loading back to false after response
    }
  };

  return (
    <Container 
      maxWidth="md"  // Widen the container
      sx={{ 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh", 
        padding: "1rem",
        marginTop: "6rem",
      }}
    >
      <Paper elevation={3} sx={{ padding: "2rem", width: "100%", borderRadius: "12px", mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#550FCC", mb: 4 }}>
          Predict Class for Model: {modelName}
        </Typography>

        {/* Option to upload file */}
        <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          style={{ marginTop: "1rem" }}
        />

        <Box mt={2}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={predict}
            disabled={loading}  // Disable button while loading
            sx={{
              padding: "10px 20px",
              borderRadius: "8px",
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec",
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Predict"}
          </Button>
        </Box>

        {isPredictionDone && topPrediction && (
          <Card sx={{ marginTop: "2rem", width: "100%", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Avatar sx={{ backgroundColor: 'green', marginRight: '10px' }}>
                  <CheckCircleIcon />
                </Avatar>
                <Typography variant="h5" component="div">
                  Prediction: {topPrediction}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: "1rem" }}>
                Confidence: {confidence}%
              </Typography>

              {/* Button to ask for explanation */}
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={getExplanation} 
                sx={{ marginTop: "1rem", borderRadius: "8px" }}
                disabled={loading}  // Disable button while loading
              >
                {loading ? <CircularProgress size={24} /> : "Ask for Explanation"}
              </Button>

              {/* Show GPT-4 explanation */}
              {explanation && (
                <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    Explanation: {explanation}
                  </Typography>
                </Box>
              )}
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                color="primary" 
                onClick={() => {
                  setIsPredictionDone(false);
                  setExplanation(null);
                  setTopPrediction(null);
                  setConfidence(null);
                }}
              >
                Try Another Image
              </Button>
            </CardActions>
          </Card>
        )}
      </Paper>

      {/* Explanatory Card Below */}
      {showExplanatoryCard && (
        <Card
          sx={{
            width: "90%", // Take 90% of the container width
            maxWidth: "800px", // Set a maximum width for responsiveness
            margin: "2rem auto", // Center the card horizontally and add vertical spacing
            padding: "2rem", // Add padding for content spacing
            borderRadius: "12px", // Rounded corners for aesthetics
            backgroundColor: "#f9f9f9",
            position: "relative",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)} // Close the card
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            <HelpOutlineIcon sx={{ fontSize: "3rem", color: "#550FCC" }} /> {/* Icon for explanation */}
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
            >
              How Does the Prediction Work?
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ lineHeight: 1.6 }} // Improve readability with line height
            >
              Once you upload an image, the model processes it and predicts which class the image most likely belongs to, 
              along with a confidence score. You can also ask for an explanation which provides more context 
              on why the model made its decision.
            </Typography>
          </CardContent>
        </Card>
      )}

    </Container>
  );
};

export default Predict;

import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
  IconButton,
  Chip,
  Collapse,
  Menu
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import api from "../api";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from '@mui/icons-material/Clear';

import FolderBreadcrumb from './FolderBreadcrumb';
import FolderTree from './FolderTree';
import FolderActions from "./FolderActions";
import FolderMenuItem from './FolderMenuItem';
import { Folder, Move, Plus, ChevronLeft } from 'lucide-react';

const SearchAndRetrieveImages = () => {
  const [query, setQuery] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchMode, setSearchMode] = useState("text");
  const [sortOrder, setSortOrder] = useState("date");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [targetUsername, setTargetUsername] = useState("");
  const [topClasses, setTopClasses] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [classLabel, setClassLabel] = useState("");
  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderLoading, setFolderLoading] = useState(false);
  const [folderError, setFolderError] = useState('');
  const [showFolderPanel, setShowFolderPanel] = useState(true);
  const [currentFolderContents, setCurrentFolderContents] = useState([]);

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [shareFolderDialogOpen, setShareFolderDialogOpen] = useState(false);
  const [currentFolderToEdit, setCurrentFolderToEdit] = useState(null);
  const [success, setSuccess] = useState('');

  const [moveDialogOpen, setMoveDialogOpen] = useState(false);

  // For creating a folder
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [expandedFolders, setExpandedFolders] = useState({});

  // For drag-and-drop visual feedback on folders
  const [dragOverFolder, setDragOverFolder] = useState(null);

  // Context menu for individual images
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuImage, setContextMenuImage] = useState(null);

  const pageSize = 12;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const getFolderPathNames = (folderId, foldersList) => {
    const path = [];
    let current = foldersList.find(f => f.id === folderId);
    while (current) {
      path.unshift({
        id: current.id,
        name: current.name
      });
      current = foldersList.find(f => f.id === current.parent_folder_id);
    }
    return path;
  };

  const deleteSelectedImages = async () => {
    if (!selectedImages.length) {
      setError("Please select at least one image to delete.");
      return;
    }
    try {
      setDeleting(true);
      setError("");
      await api.delete("/images", {
        data: { image_urls: selectedImages },
      });

      if (currentFolder) {
        setCurrentFolderContents((prevResults) =>
          prevResults.filter((result) => !selectedImages.includes(result.image_url))
        );
      } else {
        setResults((prevResults) =>
          prevResults.filter((result) => !selectedImages.includes(result.image_url))
        );
      }

      setSelectedImages([]);
      alert("Images deleted successfully");
    } catch (error) {
      setError("Error deleting images");
      console.error("Delete Images Error:", error);
    } finally {
      setDeleting(false);
    }
  };

  const fetchTopClasses = async () => {
    try {
      const response = await api.get('/classes/top');
      setTopClasses(response.data.top_classes);
    } catch (error) {
      console.error('Error fetching top classes:', error);
    }
  };

  const fetchFolders = async () => {
    try {
      setFolderLoading(true);
      // Fetch all folders recursively
      const response = await api.get('/folders', {
        params: {
          recursive: true
        }
      });
      console.log('Fetched folders:', response.data.folders);
      
      if (currentFolder) {
        console.log('Current folder:', currentFolder.id);
        console.log('Subfolders:', response.data.folders.filter(f => 
          f.parent_folder_id === currentFolder.id
        ));
      }
      
      setFolders(response.data.folders);
    } catch (error) {
      setFolderError('Failed to load folders');
      console.error('Error fetching folders:', error);
    } finally {
      setFolderLoading(false);
    }
  };

  const handleFolderSelect = async (folder) => {
    try {
      setLoading(true);
      console.log('Selecting folder:', folder);

      setCurrentFolder(folder);

      // First get the latest folders
      await fetchFolders();

      // Then fetch contents of the selected folder
      const response = await api.post('/images/search', {}, {
        params: {
          folder_id: folder?.id || null,
          recursive: false,
          page_size: pageSize,
          page_number: 1
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (folder) {
        setCurrentFolderContents(response.data.results);
        setResults([]);

        // Expand the selected folder in the tree
        setExpandedFolders(prev => ({
          ...prev,
          [folder.id]: true
        }));
      } else {
        setCurrentFolderContents([]);
        setResults(response.data.results);
      }

      setHasMore(response.data.results.length === pageSize);
      setPageNumber(1);
      setError('');
    } catch (error) {
      console.error('Failed to load folder contents:', error);
      setError('Failed to load folder contents');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchMode = (event, newMode) => {
    if (newMode !== null) {
      setSearchMode(newMode);
      setQuery("");
      setImageFile(null);
      setResults([]);
      setPageNumber(1);
      setHasMore(true);
      setError("");
      setCurrentFolder(null);
      searchImages(false, true);
    }
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
    setResults([]);
    setPageNumber(1);
    setHasMore(true);
    searchImages(false, !isSearching);
  };

  const fetchAutocomplete = async (queryText) => {
    if (queryText.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await api.get(`/autocomplete`, { params: { query: queryText } });
      setSuggestions(response.data.suggestions);
    } catch (error) {
      console.error("Error fetching autocomplete:", error);
    }
  };

  useEffect(() => {
    if (query.length > 2) {
      const debounce = setTimeout(() => fetchAutocomplete(query), 300);
      return () => clearTimeout(debounce);
    }
  }, [query]);

  useEffect(() => {
    // Initial load: fetch folders, top classes, and initial images
    const loadInitialData = async () => {
      await fetchFolders();
      await fetchTopClasses();
      await searchImages(false, true);
    };
    loadInitialData();
  }, []);

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
  };

  const goToDesignGenerator = async () => {
    try {
      // Create form data
      const formData = new FormData();
      formData.append("message", "Here are the selected images to work with.");
      
      // Add image URLs correctly
      selectedImages.forEach(url => {
        formData.append("image_urls", url);
      });
  
      const response = await api.post("/design-copilot/chat", formData);
  
      if (response.data.session_id) {
        localStorage.setItem("designChatSessionId", response.data.session_id);
        navigate("/generate-design", { 
          state: { 
            sessionId: response.data.session_id,
            fromImageSelection: true,
            selectedImages: selectedImages
          }
        });
      }
    } catch (error) {
      console.error("Error starting design session:", error);
      setError("Failed to start design session");
    }
  };
  

  const searchImages = async (loadMore = false, isInitialLoad = false) => {
    if (!loadMore) {
      setLoading(true);
      if (!isInitialLoad && !query && !classLabel && !imageFile && !currentFolder) {
        setError("Please enter a search query, select a class, or choose a folder");
        setLoading(false);
        return;
      }
      setResults([]);
      setPageNumber(1);
      setHasMore(true);
    }

    setError("");

    let requestData = {};
    let headers = {};
    let endpoint = "/images/search";

    if (searchMode === "image" && imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);

      if (startDate) formData.append("start_date", startDate.toISOString().split("T")[0]);
      if (endDate) formData.append("end_date", endDate.toISOString().split("T")[0]);
      if (classLabel) formData.append("class_label_filter", classLabel);
      if (minSize) formData.append("min_size", minSize);
      if (maxSize) formData.append("max_size", maxSize);

      if (currentFolder) {
        formData.append("folder_id", currentFolder.id);
        formData.append("recursive", true);
      }

      requestData = formData;
      endpoint = "/images/visual-search";
    } else {
      const data = {
        ...(query && { query_text: query }),
        ...(classLabel && { class_label_filter: classLabel }),
        ...(startDate && { start_date: startDate.toISOString().split('T')[0] }),
        ...(endDate && { end_date: endDate.toISOString().split('T')[0] }),
        ...(minSize && { min_size: minSize }),
        ...(maxSize && { max_size: maxSize }),
        ...(currentFolder && { folder_id: currentFolder.id, recursive: false })
      };
      requestData = data;
      headers['Content-Type'] = 'application/json';
    }

    try {
      const response = await api.post(endpoint, requestData, {
        params: {
          page_size: pageSize,
          page_number: loadMore ? pageNumber : 1,
        },
        headers: headers,
      });

      let fetchedResults = response.data.results;
      const currentSortOrder = isInitialLoad ? 'date' : 'similarity';
      setSortOrder(currentSortOrder);

      if (currentSortOrder === 'date') {
        fetchedResults.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }

      if (loadMore) {
        setResults((prevResults) => [...prevResults, ...fetchedResults]);
      } else {
        if (currentFolder) {
          setCurrentFolderContents(fetchedResults);
        } else {
          setResults(fetchedResults);
        }
      }

      if (response.data.results.length < pageSize) {
        setHasMore(false);
      }

      setPageNumber((prevPage) => prevPage + 1);

    } catch (error) {
      setError("Error retrieving images");
      console.error("Search Images Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectImage = (imageUrl) => {
    if (selectedImages.includes(imageUrl)) {
      setSelectedImages(selectedImages.filter((url) => url !== imageUrl));
    } else {
      setSelectedImages([...selectedImages, imageUrl]);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const downloadSelectedImages = async () => {
    try {
      const response = await api.post("/images/download-zip", { image_urls: selectedImages }, {
        headers: { 'Content-Type': 'application/json' },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "selected_images.zip";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      setError("Error downloading images");
      console.error("Download Images Error:", error);
    }
  };

  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
    setTargetUsername("");
    setError("");
  };

  const shareSelectedImages = async () => {
    if (!targetUsername) {
      setError("Please enter a username to share with");
      return;
    }

    try {
      await api.post("/images/share", {
        image_urls: selectedImages,
        target_username: targetUsername,
      });

      alert("Images shared successfully");
      handleCloseShareDialog();
    } catch (error) {
      setError("Error sharing images");
      console.error("Share Images Error:", error);
    }
  };

  const handleClassClick = (classLabel) => {
    setClassLabel(classLabel);
    setQuery("");
    setSearchMode('text');
    setResults([]);
    setPageNumber(1);
    setIsSearching(true);
    searchImages(false);
  };

  const handleFolderNavigate = (folder) => {
    setCurrentFolder(folder);
    searchImages(false);
  };

  const handleFolderDelete = async (folderId) => {
    try {
      await api.delete(`/folders/${folderId}`);
      await fetchFolders();
      
      if (currentFolder?.id === folderId) {
        handleFolderSelect(null);
      }
    } catch (error) {
      setError('Failed to delete folder');
      console.error('Delete Folder Error:', error);
    }
  };

  const handleMoveSelectedImages = async (targetFolderId, imagesToMove = selectedImages) => {
    if (!imagesToMove.length) return;

    try {
      setLoading(true);
      await api.post('/images/move', {
        image_urls: imagesToMove,
        folder_id: targetFolderId
      });

      // Refresh current view
      if (currentFolder) {
        handleFolderSelect(currentFolder);
      } else {
        searchImages(false, true);
      }

      // Clear selection if we moved the currently selected images
      if (imagesToMove === selectedImages) {
        setSelectedImages([]);
      }

      setError('');
    } catch (error) {
      setError('Failed to move images');
      console.error('Move Images Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMove = async (targetFolderId) => {
    try {
      await api.post("/images/move", {
        image_urls: selectedImages,
        folder_id: targetFolderId // null for root
      });

      if (currentFolder) {
        handleFolderSelect(currentFolder);
      } else {
        searchImages(false, true);
      }

      setSelectedImages([]);
      setMoveDialogOpen(false);
    } catch (error) {
      setError("Failed to move images");
      console.error("Move Images Error:", error);
    }
  };

  const handleContextMenu = (event, imageUrl) => {
    event.preventDefault();
    setContextMenuImage(imageUrl);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    setContextMenuImage(null);
  };

  const handleMoveImage = async (targetFolderId) => {
    if (!contextMenuImage) return;
    await handleMoveSelectedImages(targetFolderId, [contextMenuImage]);
    handleCloseContextMenu();
  };

  const MoveDialog = ({ open, onClose, onMove, folders }) => {
    const [selectedFolderForMove, setSelectedFolderForMove] = useState(null);

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Move Items</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Button 
              variant="outlined" 
              fullWidth
              onClick={() => {
                onMove(null); // Move to root
              }}
              sx={{ mb: 2 }}
            >
              Move to Root (No Folder)
            </Button>

            <Typography variant="subtitle2" gutterBottom>
              Or select a destination folder:
            </Typography>

            <Box sx={{ mt: 2 }}>
              <FolderTree
                folders={folders}
                selectedFolder={selectedFolderForMove}
                onFolderSelect={setSelectedFolderForMove}
                onDropInFolder={null}
                sharedFolders={[]}
                handleFolderDelete={() => {}}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            onClick={() => onMove(selectedFolderForMove?.id)}
            variant="contained"
            disabled={!selectedFolderForMove}
          >
            Move to Selected Folder
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;

    try {
      setError('');
      console.log('Creating folder:', {
        name: newFolderName,
        parent_folder_id: currentFolder?.id || null
      });

      const response = await api.post('/folders', {
        name: newFolderName,
        parent_folder_id: currentFolder?.id || null,
        description: "",
        tags: []
      });

      console.log('Created folder:', response.data);

      // Clear input and close dialog
      setNewFolderName('');
      setCreateDialogOpen(false);

      // Refresh folders
      await fetchFolders();

      // If we're in a folder, expand and re-select it
      if (currentFolder) {
        setExpandedFolders(prev => ({
          ...prev,
          [currentFolder.id]: true
        }));

        await handleFolderSelect(currentFolder);
      }

    } catch (error) {
      console.error('Create Folder Error:', error);
      setError(error.response?.data?.detail || 'Failed to create folder');
    }
  };

  const displayFolders = useMemo(() => {
    console.log('Filtering folders with current folder:', currentFolder);
  
    return folders.filter(folder => {
      const isInCurrentFolder = folder.parent_folder_id === (currentFolder?.id || null);
      const isNotDeleted = !folder.is_deleted;
      
      console.log('Folder:', {
        name: folder.name,
        parentId: folder.parent_folder_id,
        currentFolderId: currentFolder?.id,
        isInCurrentFolder,
        isNotDeleted
      });

      return isInCurrentFolder && isNotDeleted;
    });
  }, [folders, currentFolder?.id]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: "8rem" }}>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "2rem", color: "#550FCC", textAlign: "center" }}
      >
        Search and Retrieve Images
      </Typography>

      {topClasses.length > 0 && (
        <Box sx={{ marginBottom: '2rem' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
            Top Classes
          </Typography>
          <Grid container spacing={1}>
            {topClasses.map((classItem, index) => (
              <Grid item key={index}>
                <Button
                  variant="outlined"
                  onClick={() => handleClassClick(classItem.class_label)}
                  sx={{ textTransform: 'none' }}
                >
                  {classItem.class_label} ({classItem.count})
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Current Folder
            </Typography>
            {currentFolder ? (
              <Chip
                icon={<Folder size={20} />}
                label={currentFolder.name}
                onDelete={() => {
                  handleFolderSelect(null);
                }}
                sx={{ marginBottom: '1rem' }}
              />
            ) : (
              <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '1rem' }}>
                All Folders
              </Typography>
            )}
          </Box>

          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <InputLabel id="sort-order-label">Sort By</InputLabel>
            <Select
              labelId="sort-order-label"
              id="sort-order"
              value={sortOrder}
              label="Sort By"
              onChange={handleSortOrderChange}
            >
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="similarity">Similarity</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Class Label"
            variant="outlined"
            value={classLabel}
            onChange={(e) => setClassLabel(e.target.value)}
            sx={{ marginBottom: "1.5rem" }}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ marginBottom: "1.5rem" }} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ marginBottom: "1.5rem" }} />}
            />
          </LocalizationProvider>

          <TextField
            fullWidth
            label="Min Size (KB)"
            variant="outlined"
            type="number"
            value={minSize}
            onChange={(e) => setMinSize(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">KB</InputAdornment>,
            }}
            sx={{ marginBottom: "1.5rem" }}
          />

          <TextField
            fullWidth
            label="Max Size (KB)"
            variant="outlined"
            type="number"
            value={maxSize}
            onChange={(e) => setMaxSize(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">KB</InputAdornment>,
            }}
            sx={{ marginBottom: "1.5rem" }}
          />

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button
              startIcon={<Folder size={20} />}
              onClick={() => setShowFolderPanel(!showFolderPanel)}
              variant="outlined"
              size="small"
            >
              {showFolderPanel ? 'Hide Folders' : 'Show Folders'}
            </Button>
          </Box>

          <Collapse in={showFolderPanel}>
            <Paper sx={{ p: 2, mb: 2 }}>
              {folderError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {folderError}
                </Alert>
              )}

              {folderLoading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {currentFolder && (
                    <Box sx={{ mb: 2 }}>
                      <FolderBreadcrumb
                        path={[
                          { id: 'root', name: 'Root' },
                          ...getFolderPathNames(currentFolder.id, folders)
                        ]}
                        onNavigate={(folder) => {
                          setCurrentFolder(folder);
                          handleFolderSelect(folder);
                        }}
                      />
                    </Box>
                  )}

                  <FolderTree
                    folders={folders}
                    selectedFolder={currentFolder}
                    onFolderSelect={handleFolderSelect}
                    onDropInFolder={async (type, item, targetFolder) => {
                      try {
                        if (type === 'folder') {
                          await api.post(`/folders/move`, {
                            folder_id: item.id,
                            target_folder_id: targetFolder.id
                          });
                        } else if (item && item.image_url) {
                          await handleMoveSelectedImages(targetFolder.id, [item.image_url]);
                        }
                        fetchFolders();
                        if (currentFolder) {
                          handleFolderSelect(currentFolder);
                        } else {
                          searchImages();
                        }
                      } catch (error) {
                        setError('Failed to move item');
                        console.error("Move Item Error:", error);
                      }
                    }}
                    sharedFolders={[]}
                    handleFolderDelete={handleFolderDelete}
                  />
                </>
              )}
            </Paper>
          </Collapse>
        </Grid>

        <Grid item xs={12} sm={9}>
          {error && <Alert severity="error" sx={{ marginBottom: "1rem" }}>{error}</Alert>}

          <ToggleButtonGroup
            color="primary"
            value={searchMode}
            exclusive
            onChange={handleSearchMode}
            sx={{ marginBottom: "1.5rem" }}
          >
            <ToggleButton value="text">Text Search</ToggleButton>
            <ToggleButton value="image">Visual Search</ToggleButton>
          </ToggleButtonGroup>

          {searchMode === "text" && (
            <>
              <TextField
                fullWidth
                label="Enter search query"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                variant="outlined"
                sx={{ marginBottom: "1rem", borderRadius: '50px' }}
                InputProps={{
                  style: { borderRadius: '50px' }
                }}
              />

              {suggestions.length > 0 && (
                <Paper>
                  <List>
                    {suggestions.map((suggestion, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <ListItemText primary={suggestion} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </>
          )}

          {searchMode === "image" && (
            <div style={{ marginBottom: "1rem" }}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </div>
          )}

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => searchImages(false, false)}
              disabled={
                loading ||
                (searchMode === "text" && !query && !classLabel && !currentFolder) ||
                (searchMode === "image" && !imageFile)
              }
              sx={{ borderRadius: "8px" }}
            >
              {loading ? <CircularProgress size={24} /> : "Search Images"}
            </Button>

            {currentFolder && (
              <>
              <Button
                startIcon={<ChevronLeft />}
                onClick={async () => {
                  // Find and select the parent folder
                  const parentFolderId = currentFolder.parent_folder_id;
                  if (parentFolderId) {
                    const parentFolder = folders.find(f => f.id === parentFolderId);
                    if (parentFolder) {
                      await handleFolderSelect(parentFolder);
                    }
                  } else {
                    // If no parent, go to root
                    await handleFolderSelect(null);
                  }
                }}
                variant="outlined"
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Back
              </Button>
        
              <Button
                startIcon={<ClearIcon />}
                onClick={() => handleFolderSelect(null)}
                variant="outlined"
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Clear folder
              </Button>
            </>
            )}
          </Box>

          <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Folders</Typography>
              <Button
                startIcon={<Plus className="w-4 h-4" />}
                onClick={() => setCreateDialogOpen(true)}
                variant="outlined"
                size="small"
              >
                NEW FOLDER
              </Button>
            </Box>

            {displayFolders.length > 0 ? (
              <Grid container spacing={2}>
                {displayFolders.map((folder) => (
                  <Grid item key={folder.id} xs={12} sm={6} md={4}>
                    <Paper
                      sx={{
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        '&:hover': { bgcolor: 'action.hover' },
                        borderRadius: '8px'
                      }}
                      onClick={() => handleFolderSelect(folder)}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                        <Folder className="w-5 h-5" />
                        <Typography noWrap sx={{ flex: 1 }}>{folder.name}</Typography>
                      </Box>
                      <Box>
                        <FolderActions 
                          folder={folder}
                          onFolderUpdate={fetchFolders}
                          onFolderDelete={handleFolderDelete}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography color="text.secondary" textAlign="center">
                {currentFolder ? `No folders found in "${currentFolder.name}"` : 'No folders found in root'}
              </Typography>
            )}
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>Files</Typography>
            {loading ? (
              <CircularProgress />
            ) : (currentFolder ? currentFolderContents : results).length > 0 ? (
              <ImageList cols={isSmallScreen ? 2 : 4} gap={16}>
                {(currentFolder ? currentFolderContents : results).map((result) => (
                  <ImageListItem 
                    key={result.image_url}
                    sx={{ 
                      cursor: 'pointer',
                      position: 'relative',
                      '&:hover': {
                        '& .image-actions': { opacity: 1 }
                      }
                    }}
                    draggable
                    onDragStart={(e) => {
                      e.dataTransfer.setData('image_url', result.image_url);
                      e.dataTransfer.setData('type', 'image');
                    }}
                    onContextMenu={(e) => handleContextMenu(e, result.image_url)}
                  >
                    <img
                      src={`${result.image_url}?w=248&fit=crop&auto=format`}
                      srcSet={`${result.image_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={result.class_label}
                      loading="lazy"
                      onClick={() => handleImageClick(result.image_url)}
                      style={{ borderRadius: '8px' }}
                    />
                    <Checkbox
                      checked={selectedImages.includes(result.image_url)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectImage(result.image_url);
                      }}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        left: 8,
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'primary.main'
                        }
                      }}
                    />
                    <Box
                      className="image-actions"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        p: 1,
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {result.folder_name && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Folder size={16} style={{ marginRight: '4px' }} />
                          <Typography variant="caption">
                            {result.folder_name}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              <Typography variant="body1" color="text.secondary" textAlign="center" py={4}>
                No images found in {currentFolder ? `folder "${currentFolder.name}"` : 'this location'}
              </Typography>
            )}
          </Box>

          <Dialog
            open={Boolean(selectedImage)}
            onClose={() => setSelectedImage(null)}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                href={selectedImage}
                download
              >
                Download Image
              </Button>
              <Button onClick={() => setSelectedImage(null)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {selectedImages.length > 0 && (
            <Box sx={{ marginTop: "2rem", display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={downloadSelectedImages}
                sx={{ padding: "10px 20px", borderRadius: "8px" }}
              >
                Download Selected Images
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenShareDialog}
                sx={{ padding: "10px 20px", borderRadius: "8px" }}
              >
                Share Selected Images
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={deleteSelectedImages}
                disabled={deleting}
                sx={{ padding: "10px 20px", borderRadius: "8px" }}
              >
                {deleting ? <CircularProgress size={24} /> : "Delete Selected Images"}
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={goToDesignGenerator}
                sx={{ padding: "10px 20px", borderRadius: "8px" }}
              >
                Start Designing
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                startIcon={<Move size={20} />}
                onClick={() => setMoveDialogOpen(true)}
                sx={{ padding: "10px 20px", borderRadius: "8px" }}
              >
                Move Selected Images
              </Button>
            </Box>
          )}

          {hasMore && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => searchImages(true, !isSearching)}
              sx={{ marginTop: "2rem", marginBottom: "2rem", padding: "10px 20px", borderRadius: "8px" }}
            >
              {loading ? <CircularProgress size={24} /> : "Load More"}
            </Button>
          )}
        </Grid>
      </Grid>

      <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
        <DialogTitle>Share Selected Images</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the username of the user you want to share the selected images with.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={shareSelectedImages} color="primary">
            Share
          </Button>
        </DialogActions>
      </Dialog>

      <MoveDialog
        open={moveDialogOpen}
        onClose={() => setMoveDialogOpen(false)}
        onMove={(folderId) => handleMove(folderId)}
        folders={folders}
      />

      <Dialog
        open={renameDialogOpen}
        onClose={() => {
          setRenameDialogOpen(false);
          setError('');
          setSuccess('');
        }}
      >
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setError('')}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{ mb: 2 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="New Folder Name"
            fullWidth
            value={currentFolderToEdit ? currentFolderToEdit.name : ''}
            onChange={(e) => {
              if (currentFolderToEdit) {
                setCurrentFolderToEdit({ ...currentFolderToEdit, name: e.target.value });
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                // Implement handleRenameFolder API call if needed
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRenameDialogOpen(false);
              setError('');
              setSuccess('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // Implement rename logic if needed
              setRenameDialogOpen(false);
              setSuccess('Folder renamed successfully.');
            }}
            variant="contained"
            disabled={!currentFolderToEdit?.name?.trim()}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={shareFolderDialogOpen}
        onClose={() => {
          setShareFolderDialogOpen(false);
          setError('');
          setSuccess('');
        }}
      >
        <DialogTitle>Share Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the username of the user you want to share the folder with.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShareFolderDialogOpen(false);
              setError('');
              setSuccess('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // Implement share logic if needed
              setShareFolderDialogOpen(false);
              setSuccess('Folder shared successfully.');
            }}
            variant="contained"
            disabled={!targetUsername.trim()}
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          handleMoveImage(null);
        }}>
          Move to Root
        </MenuItem>
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          setSelectedImages([contextMenuImage]);
          setMoveDialogOpen(true);
          handleCloseContextMenu();
        }}>
          Move to Folder...
        </MenuItem>
      </Menu>

      {showExplanatoryCard && (
        <Paper
          elevation={3}
          sx={{
            width: "90%",
            maxWidth: "800px",
            margin: "4rem auto",
            padding: "2rem",
            borderRadius: "12px",
            backgroundColor: "#f9f9f9",
            position: "relative",
            textAlign: "center",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <ImageSearchIcon sx={{ fontSize: "3rem", color: "#550FCC", marginBottom: "1rem" }} />
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Why search and retrieve images?
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ lineHeight: 1.6 }}
          >
            Designers and professionals can use this tool to quickly manage their image libraries,
            search for specific content, and retrieve images based on both text and visual queries.
            The added filters and sorting options allow you to refine your search for optimal results,
            making it easier to locate relevant images in large collections.
          </Typography>
        </Paper>
      )}

      {/* Create Folder Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setError('');
          setSuccess('');
          setNewFolderName('');
        }}
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setError('')}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{ mb: 2 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleCreateFolder();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateDialogOpen(false);
              setError('');
              setSuccess('');
              setNewFolderName('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateFolder}
            variant="contained"
            disabled={!newFolderName.trim()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SearchAndRetrieveImages;

// FolderBreadcrumb.js

import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { ChevronRight, Home } from 'lucide-react';

const FolderBreadcrumb = ({ path, onNavigate }) => {
  return (
    <Breadcrumbs 
      separator={<ChevronRight className="w-4 h-4" />}
      className="mb-4"
    >
      <Link
        component="button"
        variant="body1"
        onClick={() => onNavigate(null)}
        className="flex items-center"
        underline="hover"
      >
        <Home className="w-4 h-4 mr-1" />
        Root
      </Link>
      {path?.map((item, index) => (
        <Link
          key={item.id}
          component="button"
          variant="body1"
          onClick={() => onNavigate(item)}
          underline={index === path.length - 1 ? "none" : "hover"}
          color={index === path.length - 1 ? "text.primary" : "inherit"}
        >
          {item.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default FolderBreadcrumb;
